<template>
  <div class="container">
    <!-- <van-nav-bar
      title="缘分墙"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
      :class="{ choose: id == item }"
    /> -->

    <div class="list">
      <div
        class="item"
        @click="admire(item)"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="info">
          <div class="info-img">
            <img :src="item.headimgurl" alt="" />
          </div>
          <div class="info-name">
            <div class="name van-ellipsis">{{ item.nickname }}</div>
            <div class="age" v-if="item.sex == 2">
              <van-icon
                size="5px"
                class-prefix="iconfont"
                name="iconfontshouyezhuyetubiao07"
              />{{ item.age }}
              <!--  <img src="../../assets/img/nv.png" alt="" /> -->
            </div>
            <div class="womanage" v-else>
              <van-icon size="5px" class-prefix="iconfont" name="nan1" />{{
                item.age
              }}
            </div>
          </div>
        </div>
        <div class="declaration">
          {{ item.description }}
        </div>
        <div class="choose" v-if="item == id">
          <img src="../../assets/img/xinyi.png" alt="" />
        </div>
      </div>
    </div>
    <div class="exchange" @click="exchange">换一换</div>
    <!-- 开通vip弹窗 -->
    <van-popup class="popup" v-model="vipShow" position="bottom" closeable>
      <div class="title">开通VIP会员</div>
      <!-- <div class="first">VIP会员才能选择心仪的对象噢！</div>
      <div class="second">
        <span class="pink">心仪的对象</span>参加的活动会提前通知你
      </div> -->
      <img class="openvip" src="../../assets/img/20.png" alt="" />
      <img class="viptwo" src="../../assets/img/21.png" alt="" />
      <div class="xieyi">
        <van-checkbox v-model="checked" checked-color="#282725" class="check"
          >同意</van-checkbox
        >
        <span @click="goxieyi">《企乐缘会员服务协议》</span>
      </div>
      <div class="btn" @click="openVip">
        ￥{{ vipPrice }}<span class="small">/月</span> 立即开通
      </div>
    </van-popup>
  </div>
</template>

<script>
import { buy_vip, payment, vip_price } from "@/api/vip";
import { user_list, follow } from "@/api/activity";
export default {
  data() {
    return {
      vipShow: false,
      checked: false,
      page: 1,
      pageSize: 6,

      list: [],
      id: null,
      show: false,
      admireShow: false,
      vipPrice: ""
    };
  },
  created() {
    this.getOpenVipPrice();
    let description = sessionStorage.getItem("description");
    console.log(description, 563214);
    if (description) {
      this.getDataList();
    } else {
      this.$router.push("/personalData");
    }
    console.log(description);
  },
  methods: {
    async getOpenVipPrice() {
      const res = await vip_price();
      console.log(res, 985);
      console.log(res.data.data.vip_price);
      if (res.data.code == 200) {
        this.vipPrice = res.data.data.vip_price;
      }
    },
    async exchange() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });
      await this.getDataList();
      this.$toast.clear();
    },
    async getDataList() {
      try {
        const res = await user_list({ pageSize: this.pageSize });
        console.log(res, "8520");
        this.list = res.data.data;
        console.log(res);
      } catch (err) {
        this.$toast(err);
      }
    },
    /* onClickLeft() {
      this.$router.back(-1);
    }, */

    async admire(item) {
      this.admireShow = true;
      this.id = item;
      console.log(item, 985);
      console.log(this.id, 211);

      const res = await follow({ user_id: item.id });
      if (res.data.code == 200) {
        this.$toast("你已成功心仪");
      }
      if (res.data.code == 1010131) {
        this.$router.push("http://h5.qly.ikuyoo.cn/");
      }
      if (res.data.code == 1010132) {
        this.vipShow = true; //kaiguan
        //this.$router.push('/')
      }
      if (res.data.code == 4004) {
        this.$toast(res.data.message);
      }
      if (res.data.code == 0) {
        this.$toast(res.data.message);
      }

      console.log(res.data.code);

      /* this.$toast({
        message: "心仪她",
        icon: "like-o"
      }); */
    },
    async openVip() {
      if (!this.checked) {
        return this.$toast("请详细阅读并同意企乐缘服务协议");
      }
      const res = await buy_vip();
      let { total, sNo } = res.data.data;
      console.log(total, sNo, 654);
      if (res.data.code == 200) {
        const res2 = await payment({ sNo, total });
        let obj = {
          appId: res2.data.appid,
          nonceStr: res2.data.nonceStr,
          out_trade_no: res2.data.out_trade_no,
          package: res2.data.package,
          paySign: res2.data.paySign,
          signType: res2.data.signType,
          timeStamp: res2.data.timeStamp
        }; /*   state: res2.data.state, */
        this.onBridgeReady(obj);
      }
    },
    onBridgeReady(par) {
      console.log(par, "支付参数");
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke("getBrandWCPayRequest", par, res => {
        console.log(res, "支付回调");
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          this.vipShow = false;
          console.log("支付成功");
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              // eslint-disable-next-line no-undef
              onBridgeReady,
              false
            );
          } else if (document.attachEvent) {
            // eslint-disable-next-line no-undef
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            // eslint-disable-next-line no-undef
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
          }
        } else {
          // eslint-disable-next-line no-undef
          onBridgeReady();
        }
      });
    },
    goxieyi() {
      this.$router.push({
        path: "/xieyi",
        query: {
          type: "1"
        }
      });
    }
  },
  destroyed() {
    this.checked = false;
  }
};
</script>

<style lang="scss" scoped>
.container {
  background: linear-gradient(180deg, #fff1f1 0%, #ffffff 89%);
  height: 100vh;

  /deep/.van-nav-bar .van-icon {
    color: #333333;
  }
  .list {
    width: 100%;
    padding: 0 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .item {
    width: 340px;
    padding: 35px 15px 53px;
    background-color: #fff;
    // margin: 14px 14px 0 14px;
    margin: 14px 0 0;
    border-radius: 14px;
    box-sizing: border-box;
    position: relative;
    .info {
      display: flex;
      .info-img {
        width: 118px;
        height: 118px;

        margin-right: 13px;
        img {
          width: 118px;
          height: 118px;
          border-radius: 50%;
        }
      }
      .info-name {
        padding: 20px 0 14px;
        .name {
          font-size: 33px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 21px;
          width: 175px;
        }
        .age {
          width: 58px;
          height: 30px;
          background: #ff89b6;
          border-radius: 4px;
          display: flex;
          font-size: 18px;
          color: #ffffff;
          justify-content: center;
          align-items: center;
          /* img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          } */
        }
        .womanage {
          width: 58px;
          height: 30px;
          background: #4dbcff;
          border-radius: 4px;
          display: flex;
          font-size: 18px;
          color: #ffffff;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .declaration {
      margin-top: 40px;
      color: #999999;
      line-height: 47px;
      font-size: 25px;
    }
    .choose {
      position: absolute;
      top: 0;
      left: 0;
      width: 340px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0.95;
      border-radius: 14px;
      z-index: 99;
      img {
        width: 90px;
        height: 90px;
      }
    }
  }

  .exchange {
    width: 240px;
    height: 104px;
    background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
    box-shadow: 0px 9px 17px 2px rgba(252, 204, 66, 0.51);
    border-radius: 52px;
    text-align: center;
    line-height: 104px;
    font-size: 36px;
    color: #fff;
    position: absolute;
    bottom: 150px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .popup {
    .title {
      font-size: 35px;
      margin: 43px 0 0 36px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 52px;
    }
    .first {
      font-size: 32px;
      text-align: center;
      font-weight: 400;
      color: #999999;
      line-height: 56px;
    }
    .second {
      text-align: center;
      font-weight: 400;
      color: #999999;
      font-size: 32px;
      line-height: 56px;
      margin-bottom: 38px;
      .pink {
        color: #ff5697;
      }
    }
    .openvip {
      width: 694px;
      height: 395px;
      margin: 0 28px;
    }
    .viptwo {
      width: 694px;
      height: 356px;
      margin: 13px 28px;
    }
    .xieyi {
      display: flex;
      align-items: center;
      margin: 60px 0 145px 63px;
    }
    .check {
      //
      /deep/.van-checkbox__label {
        color: #999999;
      }
    }

    .btn {
      position: fixed;
      bottom: 30px;
      width: 625px;
      height: 97px;
      background: linear-gradient(-30deg, #282725 0%, #282725 98%);
      border-radius: 45px;
      font-size: 35px;
      color: #ffdfa9;
      text-align: center;
      line-height: 97px;
      margin: 0 63px;
      .small {
        font-size: 23px;
      }
    }
  }
}
</style>
